import { IActiveService } from '../../pages/admin/activeAccount/IActiveAccount';
import { BeeviralReferralLinkQuery } from '../../pages/register/register';
import { ILogin, INewPassword, IRegister, updatePriorityProps } from './account.types';
import AccountService from './services';

export const loginService = async (payload: ILogin) => {
  try {
    const accountService = new AccountService();
    return accountService.login(payload);
  } catch (error) {
    throw error;
  }
};

export const loginSessionService = async (payload: string) => {
  try {
    const accountService = new AccountService();
    return accountService.loginSession(payload);
  } catch (error) {
    throw error;
  }
};

export const registerService = async (payload: IRegister, query?: BeeviralReferralLinkQuery) => {
  try {
    const accountService = new AccountService();
    return accountService.register(payload, query);
  } catch (error) {
    throw error;
  }
};

export const signUpUserService = async (payload: any) => {
  try {
    const accountService = new AccountService();
    return accountService.signUpUser(payload);
  } catch (error) {
    throw error;
  }
};

export const signUpUserAdminService = async (payload: any) => {
  try {
    const accountService = new AccountService();
    return accountService.signUpUserAdmin(payload);
  } catch (error) {
    throw error;
  }
};

export const forgotPasswordService = async (payload: string) => {
  try {
    const accountService = new AccountService();
    return accountService.forgotPass(payload);
  } catch (error) {
    throw error;
  }
};

export const resendEmailService = async (establishmentId: string) => {
  try {
    const accountService = new AccountService();
    return accountService.resendEmail(establishmentId);
  } catch (error) {
    throw error;
  }
};

export const changePriorityService = async (payload: updatePriorityProps) => {
  try {
    const accountService = new AccountService();
    return accountService.changePriority(payload);
  } catch (error) {
    throw error;
  }
};

export const getMenusService = async () => {
  try {
    const accountService = new AccountService();
    return accountService.getMenus();
  } catch (error) {
    throw error;
  }
};

export const listAccountsService = async (filters: IActiveService, page: number, limit?: number) => {
  try {
    const accountService = new AccountService();
    return accountService.listAccounts(filters, page, limit);
  } catch (error) {
    throw error;
  }
};

export const listAccountsReducerService = async (filters: IActiveService, page: number, limit?: number) => {
  try {
    const accountService = new AccountService();
    return accountService.listReducerAccounts(filters, page, limit);
  } catch (error) {
    throw error;
  }
};

export const emListAccountsReducerService = async (filters: IActiveService, page: number, limit?: number) => {
  try {
    const accountService = new AccountService();
    return accountService.emListReducerAccounts(filters, page, limit);
  } catch (error) {
    throw error;
  }
};

export const gteOneEstablishmentService = async (id: string) => {
  try {
    const accountService = new AccountService();
    return accountService.gteOneEstablishmentService(id);
  } catch (error) {
    throw error;
  }
};

export const listUsersAdminService = async (filters: any, limit?: number) => {
  try {
    const accountService = new AccountService();
    return accountService.listUsersAdmin(filters, limit);
  } catch (error) {
    throw error;
  }
};

export const listUsersService = async (filters: any, limit?: number) => {
  try {
    const accountService = new AccountService();
    return accountService.listUsers(filters, limit);
  } catch (error) {
    throw error;
  }
};

export const newPasswordService = async (payload: INewPassword) => {
  try {
    const accountService = new AccountService();
    return accountService.newPassword(payload);
  } catch (error) {
    throw error;
  }
};

export const resetPasswordConfirmation = async (payload: INewPassword) => {
  try {
    const accountService = new AccountService();
    return accountService.resetPasswordConfirmation(payload);
  } catch (error) {
    throw error;
  }
};

export const resetPassStatusCode = async (payload: any) => {
  try {
    const accountService = new AccountService();
    return accountService.passcodeStatus(payload);
  } catch (error) {
    throw error;
  }
};

export const updateEmailService = async (email: string, establishmentId: string) => {
  try {
    const accountService = new AccountService();
    return accountService.updateEmail(email, establishmentId);
  } catch (error) {
    throw error;
  }
};

export const activeAccount = async (establishment: string) => {
  try {
    const accountService = new AccountService();
    return accountService.activeAccount(establishment);
  } catch (error) {
    throw error;
  }
};

export const editAccountInfos = async (payload: any, establishmentFranchieId?: string) => {
  try {
    const accountService = new AccountService();
    return accountService.editAccountInfos(payload, establishmentFranchieId);
  } catch (error) {
    throw error;
  }
};

export const editUserAdminService = async (payload: any) => {
  try {
    const accountService = new AccountService();
    return accountService.editUserAdmin(payload);
  } catch (error) {
    throw error;
  }
};

export const editUserService = async (payload: any) => {
  try {
    const accountService = new AccountService();
    return accountService.editUser(payload);
  } catch (error) {
    throw error;
  }
};

export const editEstablishmentInfos = async (payload: any) => {
  try {
    const accountService = new AccountService();
    return accountService.editEstablishmentInfos(payload);
  } catch (error) {
    throw error;
  }
};

export const editEstablishmentBanks = async (payload: any) => {
  try {
    const accountService = new AccountService();
    return accountService.editAccountBanks(payload);
  } catch (error) {
    throw error;
  }
};

export const adminEditEstablishmentBanks = async (payload: any, establishmendId: string) => {
  try {
    const accountService = new AccountService();
    return accountService.adminEditAccountBanks(payload, establishmendId);
  } catch (error) {
    throw error;
  }
};

export const changeAccountPassword = async (payload: any) => {
  try {
    const accountService = new AccountService();
    return accountService.changeAccountPassword(payload);
  } catch (error) {
    throw error;
  }
};

export const getAccountDetails = async () => {
  try {
    const accountService = new AccountService();
    return accountService.accountDetails();
  } catch (error) {
    throw error;
  }
};

export const getLocalizationService = async (payload: any) => {
  try {
    const accountService = new AccountService();
    return accountService.getLocalization(payload);
  } catch (error) {
    throw error;
  }
};

export const getEstablishmentDetails = async (id: string) => {
  try {
    const accountService = new AccountService();
    return accountService.establishmentDetails(id);
  } catch (error) {
    throw error;
  }
};

export const getEstablishmentScore = async (id: string) => {
  try {
    const accountService = new AccountService();
    return accountService.getScore(id);
  } catch (error) {
    throw error;
  }
};

export const getEstablishmentScoreAsEstablishment = async () => {
  try {
    const accountService = new AccountService();
    return accountService.getScoreAsEstablishment();
  } catch (error) {
    throw error;
  }
};

export const getEstablishmentComments = async (id: string) => {
  try {
    const accountService = new AccountService();
    return accountService.getComments(id);
  } catch (error) {
    throw error;
  }
};

export const requestAccredit = async (franchise?: string) => {
  try {
    const accountService = new AccountService();
    return accountService.requestAccredit(franchise);
  } catch (error) {
    throw error;
  }
};

export const getNewPasswordLink = async (establishment: string) => {
  try {
    const accountService = new AccountService();
    return accountService.getNewPasswordLink(establishment);
  } catch (error) {
    throw error;
  }
};

export const getAllSignDocs = async () => {
  try {
    const accountService = new AccountService();
    return accountService.getAllSignDocs();
  } catch (error) {
    throw error;
  }
};

export const setEstablishmentSignDoc = async (establishment: string, doc: string) => {
  try {
    const accountService = new AccountService();
    return accountService.setEstablishmentSignDoc(establishment, doc);
  } catch (error) {
    throw error;
  }
};

export const deleteAccount = async (establishmentId: string) => {
  try {
    const accountService = new AccountService();
    return accountService.deleteAccount(establishmentId);
  } catch (error) {
    throw error;
  }
};