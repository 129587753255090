import BizuuConstants from '../../constants/bizuuConstants';
import provider from '../../utils/providers';

class FranchiseService {
  provider;
  token;
  constructor() {
    this.provider = provider;
    this.token = `Bearer ${localStorage.getItem(BizuuConstants.AuthLocalKey)}`;
  }
  async adminList(payload: any) {
    return this.provider.get(`/franchise/admin`, {
      params: payload,
      headers: {
        Authorization: this.token,
      },
    });
  }

  async franchiseDeleteAdmin(franchiseManagerId: string) {
    return this.provider.delete(`/franchise/admin/${franchiseManagerId}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminCreate(payload: any) {
    return this.provider.post("/franchise", payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminEdit(franchiseId: string, franchiseManagerId: string, payload: any) {
    return this.provider.put(`/franchise/admin/${franchiseId}/${franchiseManagerId}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async edit(franchiseId: string, payload: any) {
    return this.provider.put(`/franchise/${franchiseId}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async franchiseAdminInviteEstablishments(franchiseId: string, franchiseManagerId: string, payload: { establishment: string; }) {
    return this.provider.post(`/franchise/admin/invite/${franchiseId}/${franchiseManagerId}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async franchiseInviteEstablishments(franchiseId: string, payload: { establishment: string; }) {
    return this.provider.post(`/franchise/invite/${franchiseId}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminResendInviteOutherEstablishment(franchiseManagerId: string, payload: { invite: string; }) {
    return this.provider.post(`/franchise/admin/resend/invite/${franchiseManagerId}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async resendInviteOutherEstablishment(payload: { invite: string; }) {
    return this.provider.post(`/franchise/resend/invite/`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async franchiseAdminInviteOutherEstablishments(franchiseId: string, franchiseManagerId: string, payload: { outher: { email: string; name: string; }; }) {
    return this.provider.post(`/franchise/admin/invite/${franchiseId}/${franchiseManagerId}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async franchiseInviteOutherEstablishments(franchiseId: string, payload: { outher: { email: string; name: string; }; }) {
    return this.provider.post(`/franchise/invite/${franchiseId}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }
  async uploadFranchiseLogoAdmin(id: string, imageFile: any) {
    const formData = new FormData();
    formData.append('file', imageFile);
    return this.provider.put(`/franchise/admin/upload/logo/${id}`, formData,
      {
        headers: {
          'Authorization': this.token,
          'Content-Type': 'multipart/form-data'
        }
      });
  }

  async uploadFranchiseLogo(imageFile: any) {
    const formData = new FormData();
    formData.append('file', imageFile);
    return this.provider.put(`/franchise/upload/logo`, formData,
      {
        headers: {
          'Authorization': this.token,
          'Content-Type': 'multipart/form-data'
        }
      });
  }

  async uploadFranchiseCoverAdmin(id: string, imageFile: any) {
    const formData = new FormData();
    formData.append('file', imageFile);
    return this.provider.put(`/franchise/admin/upload/cover/${id}`, formData,
      {
        headers: {
          'Authorization': this.token,
          'Content-Type': 'multipart/form-data'
        }
      });
  }

  async editFranchiseEstablishmentTagAdmin(franchiseId: string, franchiseManagerId: string, payload: { franchiseParticipant: string; tag: string; }) {
    return this.provider.put(`/franchise/admin/participant/${franchiseId}/${franchiseManagerId}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }


  async editFranchiseEstablishmentTag(franchiseId: string, payload: { franchiseParticipant: string; tag: string; }) {
    return this.provider.put(`/franchise/participant/${franchiseId}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async uploadFranchiseCover(imageFile: any) {
    const formData = new FormData();
    formData.append('file', imageFile);
    return this.provider.put(`/franchise/upload/cover`, formData,
      {
        headers: {
          'Authorization': this.token,
          'Content-Type': 'multipart/form-data'
        }
      });
  }



  async adminGetFranchiseById(franciseId: string) {
    return this.provider.get(`/franchise/admin/${franciseId}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async getFranchiseById() {
    return this.provider.get(`/franchise/`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminGetParticipants(franchiseManagerId: string, page = 1) {
    return this.provider.get(`/franchise/admin/participant/list/${franchiseManagerId}`, {
      headers: {
        Authorization: this.token,
      },
      params: {
        page
      }
    });
  }

  async getParticipants(page = 1) {
    return this.provider.get(`/franchise/participant/list`, {
      headers: {
        Authorization: this.token,
      },
      params: {
        page
      }
    });
  }

  async adminGetOutherParticipants(franchiseManagerId: string) {
    return this.provider.get(`/franchise/admin/participant/list/others/${franchiseManagerId}`, {
      headers: {
        Authorization: this.token,
      }
    });
  }

  async getOutherParticipants() {
    return this.provider.get(`/franchise/participant/list/others`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminDeleteInvite(inviteId: string, managerId: string) {
    return this.provider.delete(`/franchise/admin/invite/${inviteId}/${managerId}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async adminDeleteParticipant(participantId: string, managerId: string) {
    return this.provider.delete(`/franchise/admin/participant/${participantId}/${managerId}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async deleteParticipant(participantId: string, managerId: string) {
    return this.provider.delete(`/franchise/participant/${participantId}/${managerId}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async deleteInvite(inviteId: string) {
    return this.provider.delete(`/franchise/invite/${inviteId}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async listDropDownParticipants() {
    return this.provider.get(`/franchise/establishment/list`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async getFranchiseAccountParticipants(establishmentId: string) {
    return this.provider.get(`/franchise/establishment/id/${establishmentId}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async createProduct(payload: any) {
    return this.provider.post(`/franchise/create/product`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async receiversProduct(payload: { receivers: string[] }, productId: string) {
    return this.provider.put(`/franchise/update/product/receivers/${productId}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async editProduct(payload: any, productId: string) {
    return this.provider.put(`/franchise/update/product/${productId}`, payload, {
      headers: {
        Authorization: this.token,
      },
    });
  }

  async uploadProductimg(file: any, productId: string) {
    const formData = new FormData();
    formData.append('file', file);
    return this.provider.put(`/franchise/update/product/image/${productId}`, formData, {
      headers: {
        Authorization: this.token,
        'Content-Type': 'multipart/form-data'
      },
    });
  }

  async getProdutEstablishments(productId: string) {
    return this.provider.get(`/franchise/product/establishment/list/${productId}`, {
      headers: {
        Authorization: this.token,
      },
    });
  }
}

export default FranchiseService;